<template>
  <div>
    <van-popup v-model="show" @closed="closed" round :style="{ minWidth: '80%' }">
      <div style="background-color: white;  display: flex;flex-direction: column;margin-bottom: 0.5rem;">
        <div style="width: 100%; text-align: center;text-align: center; margin-top: 0.5rem;">
          <p style="font-weight: 600; color: rgb(79 77 77);font-size: 1rem;">电影购票协议</p>
        </div>
        <div style="overflow-y: scroll; height: 17rem;margin-top: 0.5rem; font-size: 0.9rem;padding: 0 1rem;">
          <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;text-indent:1em">
            用户点击同意本协议之前，请务必认真阅读、完全理解本协议中全部条款，特别是其中与用户权益有或可能具有重大关系的条款(包括但不限于第1.1条、第1.2条、第1.3条、第2.2条)。当用户按照页面提示阅读、点击确认同意本协议及完成支付购票时，即表示用户已经充分阅读、理解并接受本协议的全部内容。如用户不同意接受本协议的任何条款，或无法理解本协议相关条款含义的，请不要进行后续操作(包括但不限于点击确认或继续使用十节优选平台服务等)。
          </p>
          <div style="margin-left: 0.5rem;">
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;">1.购票须知:</p>
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;">1.1请确认场次和时间无误，购买成功后将不可退票、不可改签。</p>
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem;color: #666;">
              1.2由于设备故障、片方原因、政策原因等不可抗力因素，存在少量场次取消的情况，平台接到影院通知后，平台会尽快为用户进行退票退款。</p>
            <p style="color: #666;margin: 0.2rem 0.2rem 0.2rem 0.5rem;">1.3由于影院系统不稳定等因素，导致出票失败的情况，平台会尽快安排退款。</p>
            <p style="color: #666;margin: 0.2rem 0.2rem 0.2rem 0.5rem;">
              1.4如发现作弊行为(包括但不限于批量刷单、恶意投诉等)，十节优选平台有权取消该用户后续的退换票购票资格，必要时追究法律责任。</p>
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;">2.其他。</p>
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;">
              2.1用户使用退换服务有任何问题，可随时拨打平台客服电话。如涉及与影院退换规则相关的事项，平台将尽力协助用户与影院进行沟通。</p>
            <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: #666;">2.2
              十节优选平台如根据自身运营和管理需要不定期修订本协议及/或相关服务规则，将提前予以公示，不再向用户单独通知。用户如不接受变更后条款、内容、规则，有权立即停止使用平台服务，用户如继续使用平台服务，则视为接受变更后的条款、内容、规则。请用户注意及时查阅。
            </p>
          </div>
        </div>
      </div>
      <van-checkbox checked-color="#D59A54" v-model="check" style="color: white;font-size: 0.9rem;margin-left: 0.5rem;"><span style="color: #666;">我已同意以上协议并继续购票</span></van-checkbox>
      <div class="bottomBox">
        <div class="conte" @click="goBuy">继续购票</div>
        <div class="conteItem">
          <van-button type="primary" color="#999" class="width-100 btn" @click="closed" style="font-size: 1rem;border-radius: 2rem;">暂不购票</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    checkT: Boolean
  },
  data () {
    return {
      show: true,
      check: this.checkT
    };
  },
  components: {},
  methods: {
    goBuy () {
      if (!this.check) {
        this.$Toast('请先勾选同意以上协议')
      } else {
        this.$emit("closed", this.check);
      }
    },
    closed () {
      this.$emit("closed", false);
    },
    change (value) {
      this.check = value
    },

  },
  mounted () {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.bottomBox {
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  width: 100%;
}
.conteItem {
  width: 95%;
  margin: 0 auto;
}
.conte {
  border-radius: 2rem;
  width: 95%;
  // width: 100%;
  text-align: center;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  font-weight: 600;
  color: white;
  font-size: 1rem;
  margin: 0 auto;
}

.btn {
  height: 2.5rem;
  margin-top: 0.5rem;
}
</style>