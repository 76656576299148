<template>
  <div style="height: 100vh;background-color: #F8F8F8; overflow: hidden;">
    <div style="height: 100vh; overflow-y: scroll;">
      <div class="movieDetail">
        <div class="flex">
          <div>
            <img :src="movieData?.moviePicUrl" alt="" class="img" />
          </div>
          <div class="p-lr-10" style="font-size: 1rem;">
            <span style=" font-weight: 600;"> {{ movieData?.movieName }}</span>
            <div style="display: flex;flex-direction: row;justify-content: space-between;">
              <span style="font-size: 0.85rem; margin-top: 0.2rem;"> {{ movieData?.moviePlayTime }}</span>
              <span style="font-size: 0.85rem;margin-left: 0.5rem;"> {{ movieData?.hallType }}</span>
            </div>
            <p style="margin-top: 0.5rem;color: #666;font-size: 0.85rem;"> {{ movieData?.cinemaName }}</p>

            <span style="color: #666;font-size: 0.85rem; margin-top: 0.3rem;">{{
              movieData?.hallName }}</span>
            <div>
              <span style="font-size: 0.85rem;margin-top: 0.3rem;">{{ seatNames }}</span>
            </div>
          </div>
        </div>
        <div class="flex" style="margin:1rem 0 0 0;font-size: 0.9rem; justify-content: space-between;">
          <div style="font-weight: 600;"> <van-icon name="clear" color="#D33B2E" /><span> 不支持退款</span>
            <van-icon name="clear" color="#D33B2E" style="margin-left: 0.5rem;" /><span> 不支持改签</span>
          </div>
          <div style="color: #999;" @click="showTags">
            购票协议<van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="movieDetail">
        <span style="font-weight: 600;margin-bottom: 0.8rem;font-size: 0.95rem;margin-top: 0.2rem;">手机号：{{ LoginPhone
          }}</span>
        <div class="flex"> <van-icon name="warning-o" color="#D59A54" /><span
            style="color: #D59A54;margin-left: 0.5rem;">
            特别提示</span> </div>
        <span style="margin-top: 0.5rem;color: #666;margin-bottom: 0.2rem;font-size: 0.95rem;">
          当前手机号仅作为下单使用，将不再接收验票码，您可在详情页中查看验票码。</span>

      </div>
      <div class="movieDetail">
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
          <span>合计应付</span>
          <span style="color:#D33B2E ; font-weight: 600;">{{ form?.productTotal }}点</span>
        </div>
        <div
          style="display: flex;flex-direction: row;justify-content: space-between;font-weight: 600;margin-top: 0.5rem;">
          <span>商品合计</span>
          <span style=" font-size: 0.95rem;">{{ form?.productTotal }}点</span>
        </div>
      </div>
      <div class="movieDetail" style="padding-bottom: 3rem;">
        <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem; color: black;font-weight: 600;">购票须知:</p>
        <p style="margin: 0.5rem 0.2rem 0.2rem 0.5rem; color: #666; font-size: 0.9rem;">1.请确认场次和时间无误，购买成功后将不可退票、不可改签；
        </p>
        <p style="margin: 0.2rem 0.2rem 0.2rem 0.5rem;color: #666; font-size: 0.9rem;">
          2.由于设备故障等不可抗力因素，存在少量场次取消的情况，我们将及时为您退票退款，支付金额将原路退还；</p>
        <p style="color: #666; font-size: 0.9rem;margin: 0.2rem 0.2rem 0.2rem 0.5rem;">
          3.由于影院系统不稳定等原因，可能存在出票失败的情况，我们将及时为您退票退款，支付金额将原路退还；</p>
        <p style="color: #666; font-size: 0.9rem;margin: 0.2rem 0.2rem 0.2rem 0.5rem;">
          4.订单支付成功并出票后，验票信息请在“我的-全部订单-订单详情”中查看。
        </p>
      </div>
    </div>
    <div
      style="width: 100%; display: flex;flex-direction: row; justify-content: space-between; position: absolute;bottom: 0;background-color: white;padding: 0.5rem 0;">
      <div class="flex" style="margin-left: 1rem;"> 实付款：<div
          style="color: #D33B2E;padding-top: 0.1rem;font-weight: 600;">
          {{ form?.productTotal }} <span style="font-size: 0.9rem; font-weight: 400;">点</span></div>
      </div>
      <div @click="lockseat" class="bottomT" :disabled="disabledSub">
        提交订单
      </div>
    </div>
    <get-know v-if="showtag" @closed="closeds" :checkT="checkTag"></get-know>
  </div>
</template>

<script>

import { Toast } from "vant";
import GetKnow from '../../components/movie/GetKnow'
export default {
  name: 'ConfigOrder',
  data() {
    return {
      form: null,
      movieData: null,
      LoginPhone: '',
      showtag: false,
      checkTag: false,
      disabledSub: false,
      seatNames: '',
    }
  },
  created() { },
  components: { GetKnow },
  methods: {
    closeds(val) {
      this.checkTag = val
      this.showtag = false
      if (val) {
        this.lockseat()
      }
    },
    showDialog() {
      this.$Dialog.confirm({
        title: "提示",
        message: "您有订单未支付，请先支付后再下单",
        confirmButtonText: '去查看',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$router.push({
            path: "/AllOrder",
            query: {
              active: 1
            },
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    showTags() {
      this.showtag = true
    },
    lockseat() {
      this.disabledSub = true
      if (!this.checkTag) {
        this.showtag = true
        this.disabledSub = false
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      this.$api
        .lockOrder(this.form)
        .then((res) => {
          Toast.clear();
          if (res.code == 1) {
            if (res.data == 1) {
              this.showDialog()
            } else
              this.$Toast(res.msg, 5);
          } else {
            let orderId = res.data.qmmOrderId
            if (orderId) {
              this.toPay(orderId, res.data.orderNo, res.data.orderId)
            } else {
              this.$Toast(res.msg, 5);
            }
          }
          this.disabledSub = false
        })
        .catch((err) => {
          this.disabledSub = false
          Toast.clear();
        });
    },
    toPay(orderId, orderNo, orderId2) {
      let entrCode = localStorage.getItem('entrCode')
      if (entrCode == 'E3') {
        this.$router.push({
          path: "/MovieCashier",
          query: {
            order: orderId,
            orderNo: orderNo,
            orderId2: orderId2,
            creatTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          },
        });
      } else
        this.$router.push({
          path: "/Cashier",
          query: {
            order: orderId,
            orderNo: orderNo,
            orderId2: orderId2,
            creatTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          },
        });
    },

  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/Cashier' || to.path === '/MovieCashier') {
      to.meta.keepAlive = false
    }
    next()
  },
  mounted() {
    this.form = JSON.parse(localStorage.getItem('lockseatform'))
    this.movieData = JSON.parse(this.form.orderDetail)
    this.seatNames = this.movieData?.seatNames.slice(0, this.movieData?.seatNames.length - 1)
    this.LoginPhone = localStorage.getItem('loginphone')
  },
}

</script>
<style lang="scss" scoped>
.img {
  width: 5rem;
  height: 7rem;
  border-radius: 0.5rem;
}

.bottomT {
  margin-right: 1rem;
  border-radius: 1rem;
  padding: 0.2rem 1rem 0.3rem 1rem;
  background-image: linear-gradient(left, #ff8e57 0%, #d23b2e 100%);
  color: white;
}

.bottomG {
  margin-right: 1rem;
  border-radius: 1rem;
  padding: 0.2rem 1rem 0.3rem 1rem;
  background-color: #ccc;
  color: white;
}

.movieDetail {
  display: flex;
  margin: 1rem;
  padding: 0.8rem;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
}
</style>